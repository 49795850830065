/* footer */
footer {
    font-size: 14px;
    color: #787878;
    ul {
        @include ul-default;
    }
    a {
        color: #787878;
    }
    .container {
        display: flex;
        height: 100px;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    p {
        margin: 0;
    }
    .socials {
        @include ul-default;
        display: flex;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        li {
            margin: 10px;
            a {
                display: flex;
                width: 50px;
                height: 50px;
                font-size: 25px;
                justify-content: center;
                align-items: center;
                background-color: #1b1b1b;
                &:hover {
                    color: #ff181f;
                }
            }
        }
    }
    .artmedia {
        display: none;
        /* display: flex; */
        align-items: center;
    }
    @media screen and (max-width: 1024px) {
        .container {
            height: 80px;
        }
        .socials {
            li {
                a {
                    width: 40px;
                    height: 40px;
                    font-size: 22px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        text-align: center;
        .container {
            height: auto;
            flex-direction: column;
            justify-content: center;
            padding: 15px 15px;
        }
        .socials {
            position: relative;
            top: 0;
            left: 0;
            transform: translate(0);
            margin: 5px auto;
            li {
                a {
                    font-size: 20px;
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }
}
/* footer */