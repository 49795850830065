/* page content */
.page {
	.news1, .news {
		.date {
			color: #e31e24;
		}
	}
	.page_nav {
		.active {
			color: #e31e24;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #e31e24;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .back_link {
        margin: 25px 0;
    }
    p a {
        color: #e31e24;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .pagination {
        margin-bottom: 25px;
    }
    .map {
        margin-top: 100px;
        &.map--fitness {
            margin-top: 25px;
        }
    }
    @media screen and (max-width: 1200px) {
        .map {
            margin-top: 50px;
        }
    }
	@media screen and (max-width: 1024px) {
		#photos-list td img {
			width: 100%;
			height: auto;
		}
        .map {
            margin-top: 30px;
        }
	}
	@media screen and (max-width: 480px) {
		p img, p strong img, p iframe {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
    height: 280px;
    position: relative;
	font-size: 18px;
    margin-bottom: 100px;
    &:before {
        content: '';
        background-color: #000;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        opacity: 0.3;
    }
    &:after {
        content: '';
        height: 45px;
        background-color: rgba(255,255,255,0.4);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0; 
    }
    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .container {
        display: flex;
        align-items: center;
        height: 100%;
        position: relative;
        > ul {
            @include ul-default;
            width: 1170px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 9px;
            padding: 0 15px;
            margin: 0 auto;
            line-height: 1;
            z-index: 2;
            li {
                padding: 5px 0px;
                color: #e31e24;
                @include inline-block;
                a {
                    text-decoration: none;
                    color: #2b2a5c;
                    &:hover {
                        color: #e31e24;
                    }
                }
                &:after {
                    content: '';
                    width: 2px;
                    height: 15px;
                    background-color: #d1d1d1;
                    margin: 2px 15px 0px 18px;
                    @include inline-block;
                }
                &:last-of-type:after {
                    display: none;
                }
            }
        }
    }
    .map {
        margin-top: 0;
        margin-bottom: 45px;
    }
    &.breadcrumbs--cts {
        height: auto;
    }
    @media screen and (max-height: 1170px) {
        .container {
            > ul {
                width: auto;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        height: 200px;
        font-size: 16px;
        margin-bottom: 50px;
        .container > ul li {
            &:after {
                margin-left: 12px;
                margin-right: 10px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        height: auto;
        font-size: 14px;
        margin-bottom: 30px;
        &:after {
            height: auto;
            top: 0;
            bottom: 0;
        }
        .container {
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            padding: 10px 15px 20px;
            > ul {
                width: 100%;
                position: relative;
                bottom: 0;
                padding: 0;
                order: 1;
                li {
                    &:after {
                        height: 13px;
                        margin: 1px 7px 0px 10px;
                    }
                }
            }
            h1 {
                order: 2;
                margin: 15px 0 0;
            }
        }
        .map {
            margin: 0;
        }
    }
}
/* breadcrumbs */

/* page about */
.page {
    .about {
        margin-top: -100px;
    }
    .advantages {
        padding: 90px 0 0px;
        margin: 0;
        .advantages__item {
            &:nth-last-child(-n+3) {
                margin-bottom: 0;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .advantages {
            padding: 50px 0 0;
        }
    }
    @media screen and (max-width: 1024px) {
        .about {
            margin-top: -50px;
        }
        .advantages {
            padding-top: 30px;
        }
    }
    @media screen and (max-width: 768px) {
        .about {
            margin-top: -30px;
        }
    }
}
/* page about */

/* page catalog */
.page {
    .cat {
        padding: 0;
    }
    .cat--inside {
        margin-bottom: 0;
    }
}
.cat--unit {
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    .left {
        width: 460px;
        img {
            display: block;
            width: 100%;
        }
    }
    .right {
        width: calc(100% - 460px);
        padding-left: 95px;
        padding-right: 35px;
        ul {
            @include ul-default;
        } 
        h3 {
            margin: 40px 0 10px;
        }
    }
    .cat--unit__list {
        display: flex;
        position: relative;
        justify-content: space-between;
        padding: 30px 25px 30px 0!important;
        &:before {
            content: '';
            background-color: #1b1b1b;
            position: absolute;
            top: 0;
            bottom: 0;
            left: -95px;
            right: -35px;
            z-index: -1;
        }
        li {
            p {
                margin-bottom: 10px;
            }
            .num {
                display: block;
                font-family: $boldB;
                font-size: 27px;
                color: #ff171e;
                text-transform: uppercase;
            }
        }
    }
    .cat--table {
        margin: 0 0 30px;
    }
    ul.check--list {
        margin: 35px 0;
    }
    .check--list {
        li {
            &:before {
                top: 9px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        font-size: 14px;
        .right {
            padding: 0 0 0 20px;
        }
        .cat--unit__list {
            padding: 25px 15px 15px!important;
            flex-wrap: wrap;
            &:before {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
            li {
                padding-right: 10px;
                margin-bottom: 10px;
                .num {
                    font-size: 22px;
                }
            }
        }
        .check--list {
            li:before {
                top: 7px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .left {
            width: 300px;
        }
        .right {
            width: calc(100% - 300px);
        }
        .cat--table {
            margin-bottom: 20px;
        }
        ul.check--list {
            margin: 20px 0;
        }
    }
    @media screen and (max-width: 600px) {
        .left {
            width: 220px;
        }
        .right {
            width: calc(100% - 220px);
        }
    }
    @media screen and (max-width: 500px) {
        flex-wrap: wrap;
        .left {
            width: 100%;
        }
        .right {
            width: 100%;
            padding: 0;
            margin-top: 20px;
            h3 {
                margin-top: 20px;
            }
        }
    }
}

.cat--unit__list {
    display: flex;
    position: relative;
    justify-content: space-between;
    li {
        p {
            margin-bottom: 10px;
        }
        .num {
            display: block;
            font-family: $boldB;
            font-size: 27px;
            color: #ff171e;
            text-transform: uppercase;
            sup {
                font-size: 27px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        li {
            .num {
                font-size: 24px;
                sup {
                    font-size: 24px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        li {
            .num {
                font-size: 20px;
                sup {
                    font-size: 20px;
                }
            }
        }
    }
}

.cat--fitness {
    display: flex;
    margin-top: -100px;
    .left {
        display: flex;
        width: 50%;
        justify-content: flex-end;
        background-color: #1b1b1b;
        padding: 100px 50px 100px 0;
    }
    .right {
        display: flex;
        width: 50%;
        font-size: 16px;
        justify-content: flex-start;
        padding: 100px 0px 100px 50px;
    }
    .cat--fitness__block {
        width: 525px;
    }
    ul {
        @include ul-default;
    }
    .cat--unit__list {
        display: flex;
        width: 430px;
        flex-wrap: wrap;
        justify-content: space-between;
        text-align: center;
        li {
            width: 180px;
            margin-bottom:  40px;
        }
        .num {
            font-size: 42px;
        }
    }
    h3 {
        margin: 0 0 15px;
    }
    .cat--fitness__block {
        > ul > li {
            margin-bottom: 35px;
        }
    }
    @media screen and (max-width: 1200px) {
        .left, .right {
            padding: 25px 15px;
        }
        .cat--fitness__block {
            width: 100%;
            > ul > li {
                margin-bottom: 20px;
            }
        }

    }
    @media screen and (max-width: 1024px) {
        margin-top: -50px;
        .right {
            font-size: 14px;
        }
        .cat--unit__list {
            width: auto;
            li {
                width: calc(50% - 10px);
                .num {
                    font-size: 36px;
                    line-height: 1;
                    sup {
                        font-size: 20px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        margin-top: -30px;
        .cat--unit__list li {
            .num {
                font-size: 28px;
                sup {
                    font-size: 18px;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        .left, .right {
            width: 100%;
        }
        .right {
            padding-bottom: 10px;
        }
    }
}

.fitness--slider {
    margin-bottom: 100px!important;
    .slick-dots{
        display: none!important;
    }
    .slick-slide {
        height: auto;
        img {
            height: auto;
        }
        &:after {
            background-color: rgba(0,0,0,.2)
        }
    }
    &.dots {
        .slick-dots {
            bottom: 60px;
        }
    }
    .slick-arrow {
        display: block!important;
        width: 40px;
        height: 40px;
        font-family: 'fontAwesome';
        font-size: 40px;
        opacity: 1!important;
        visibility: visible!important;
        z-index: 1;
        &:before {
            display: none;
        }
        &:after {
            content: '\f053';
            color: #e31e24;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
            opacity: 0.7;
            transition: 300ms;
        }
        &.slick-prev {
            left: 15%;
        }
        &.slick-next {
            right: 15%;
            &:after {
                content: '\f054';
            }
        }
        &:hover {
            &:after {
                opacity: 1;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        margin-bottom: 50px!important;
        &.dots {
            .slick-dots {
                bottom: 40px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 30px!important;
        &.dots {
            .slick-dots {
                bottom: 20px;
            }
        }
        .slick-arrow {
            width: 30px;
            height: 30px;
            font-size: 30px;
            &:after {
                opacity: 1;
            }
            &.slick-prev {
                left: 10%;
            }
            &.slick-next {
                right: 10%;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            height: auto;
            img {
                height: auto;
            }
        }
        .slick-arrow {
            width: 25px;
            height: 25px;
            font-size: 25px;
            &.slick-prev {
                left: 5%;
            }
            &.slick-next {
                right: 5%;
            }
        }
    }
    @media screen and (max-width: 380px) {
        .slick-slide {
        }
    }
}
/* page catalog */

/* page contacts */
.cts {
    display: flex;
    font-size: 16px;
    margin-top: -100px;
    .left {
        display: flex;
        width: 50%;
        justify-content: flex-end;
        padding: 100px 50px 100px 0;
        .cts__block {
            width: 520px;
            > ul > li {
                padding: 40px 0;
                border-bottom: 2px solid #323232;
                &:first-of-type {
                    padding-top: 0;
                }
                > ul > li {
                    margin: 9px 0;
                }
            }
        }
    }
    .right {
        display: flex;
        width: 50%;
        justify-content: flex-start;
        background-color: #1b1b1b;
        padding: 100px 0px 100px 100px;
        .cts__block {
            width: 480px;
            padding-top: 32px;
        }
        h3 {
            margin: 60px 0px 25px;
        }
    }
    ul {
        @include ul-default;
    }
    h3 {
        margin: 0 0 15px;
    }
    .form {
        input[type="text"],
        input[type="email"],
        textarea {
            margin-bottom: 20px;
        }
        .btn {
            margin-top: 20px;
        }
    }
    @media screen and (max-width: 1200px) {
        .left {
            padding: 25px 15px;
            .cts__block {
                width: 100%;
            }
        }
        .right {
            padding: 25px 15px;
            .cts__block {
                width: 100%;
            }
            h3 {
                margin-top: 30px;
                margin-bottom: 20px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        margin-top: -50px;
        .right {
            h3 {
                margin-top: 15px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        margin-top: -30px;
        .right {
            padding-top: 0px;
            h3 {
                margin-bottom: 15px;
            }
            .cts__block {
                padding: 0;
            }
        }
        .form {
            margin-top: 25px;
            input[type="text"],
            input[type="email"],
            textarea {
                margin-bottom: 15px;
            }
            .btn {
                margin-top: 15px;
            }
        }
    }
    @media screen and (max-width: 680px) {
        flex-wrap: wrap;
        .left, .right {
            width: 100%;
        }
        .left {
            padding: 0 15px;
            .cts__block {
                > ul > li {
                    padding: 20px 0!important;
                }
            }
        }
        .right {
            margin-top: 15px;
        }
    }
    @media screen and (max-width: 340px) {
        .form {
            .g-recaptcha {
                transform: scale(0.96);
                transform-origin: 0 0;
            }
        }
    }
}
.map__block {
    h3 {
        color: #000;
        margin: 20px 0 15px;
    }
    ul {
        @include ul-default;
        li {
            margin: 3px 0;
            a {
                font-size: 14px;
                color: #000;
            }
        }
    }
}
/* page contacts */

/* modal */
.modal {
    display: none;
    background-color: rgba(0,0,0,0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    .modal__layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .modal__block {
        width: 420px;
        background-color: #1b1b1b;
        position: absolute;
        padding: 30px 20px;
        left: 0;
        right: 0;
        top: 50%;
        margin: 0 auto;
        box-shadow: 0px 0px 25px #fff;
        transform: translateY(-50%);
        h4 {
            font-size: 22px;
            text-align: center;
            padding: 0 15px;
            margin: 0;
        }
        p {
            text-align: center;
            margin: 10px 0 15px;
        }
    }
    form {
        display: block;
        padding-bottom: 130px;
        margin-top: 15px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            width: 100%;
            min-height: 45px;
            background-color: #323232;
            font-family: $regular;
            font-size: 16px;
            color: #c6c6c6;
            padding: 12px 20px;
            margin-bottom: 15px;
            border: none;
            box-shadow: none;
            line-height: 1;
            &::placeholder {
                color: #c6c6c6;
            }
        }
        label {
            position: relative;
            sup {
                font-size: 16px;
                color: red;
                position: absolute;
                left: 9px;
                top: -3px;
                line-height: 1;
            }
        }
        .g-recaptcha {
            float: right;
        }
        .btn {
            float: right;
            margin: 15px 0 0 0;
        }
    }
    @media screen and (max-width: 1200px) {
        .modal__block {
            h4 {
                font-size: 18px;
            }
        }
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 50px;
                font-size: 16px;
                padding: 14px 15px;
            }
            label {
                sup {
                    left: 6px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .modal__block {
            padding: 25px 20px;
            p {
                font-size: 14px;
            }
        }
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 40px;
                font-size: 14px;
                padding: 10px 15px;
                margin-bottom: 15px;
                &::placeholder {
                    padding-top: 2px;
                }
            }
            .btn {
                margin-top: 10px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .modal__block {
            width: auto;
            left: 10px;
            right: 10px;
        }
    }
    @media screen and (max-width: 380px) {
        form {
            .g-recaptcha {
                transform: scale(0.85);
                transform-origin: 100% 50%;
            }
        }
    }
}

.call--btn {
    position: fixed;
    bottom: 100px;
    right: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #33c700;
    font-family: 'FontAwesome';
    text-decoration: none!important;
    width: 70px;
    height: 70px;
    text-align: center;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.7);
    box-sizing: border-box;
    outline: none!important;
    z-index: 1;
    &:hover {
        i {
            animation-name: phone;
            animation-iteration-count: 1;
            animation-duration: 0.7s;
        }
    }
    i {
        display: block;
        font-size: 40px;
        color: #fff;
    }
    &:after, &:before  {
        content: '';
        width: 90%;
        height: 90%;
        background-color: transparent;
        border: 1px solid #33c700;
        position: absolute;
        top: 5%;
        left: 5%;
        animation-iteration-count: infinite;
        animation-duration: 1.2s;
        animation-name: ring;
        outline: none;  
    }
    &:after {
        animation-delay: 0.6s;
    }
    &:before {
        animation-delay: 0.8s;
    }
    @media screen and (max-width: 1440px) {
        margin-right: 0;
        right: 35px;
        bottom: 75px;
    }
    @media screen and (max-width: 991px) {
        width: 50px;
        height: 50px;
        right: 15px;
        bottom: 80px;
        i {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 768px) {
        width: 40px;
        height: 40px;
        bottom: 50px;
        i {
            font-size: 20px;
        }
    }
}

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.1;
    transform: scale(1.7);
  }
}
@keyframes phone {
  20% {
   transform: rotate(-30deg); 
  }

  80% {
    transform: rotate(390deg)
  }

  100% {
    transform: rotate(360deg);
  }
}

/* modal */

/* page content */