/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal;
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 5, 2019 */
@font-face {
    font-family: 'bebas_neuebold';
    src: url('../fonts/bebasneue_bold-webfont.eot');
    src: url('../fonts/bebasneue_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue_bold-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_bold-webfont.woff') format('woff'),
         url('../fonts/bebasneue_bold-webfont.ttf') format('truetype'),
         url('../fonts/bebasneue_bold-webfont.svg#bebas_neuebold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'bebas_neuelight';
    src: url('../fonts/bebasneue_light-webfont.eot');
    src: url('../fonts/bebasneue_light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue_light-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_light-webfont.woff') format('woff'),
         url('../fonts/bebasneue_light-webfont.ttf') format('truetype'),
         url('../fonts/bebasneue_light-webfont.svg#bebas_neuelight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'bebas_neueregular';
    src: url('../fonts/bebasneue_regular-webfont.eot');
    src: url('../fonts/bebasneue_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue_regular-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_regular-webfont.woff') format('woff'),
         url('../fonts/bebasneue_regular-webfont.ttf') format('truetype'),
         url('../fonts/bebasneue_regular-webfont.svg#bebas_neueregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'bebas_neuebook';
    src: url('../fonts/bebasneue_book-webfont.eot');
    src: url('../fonts/bebasneue_book-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue_book-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_book-webfont.woff') format('woff'),
         url('../fonts/bebasneue_book-webfont.ttf') format('truetype'),
         url('../fonts/bebasneue_book-webfont.svg#bebas_neuebook') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotoregular';
    src: url('../fonts/roboto-regular-webfont.eot');
    src: url('../fonts/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto-regular-webfont.woff') format('woff'),
         url('../fonts/roboto-regular-webfont.ttf') format('truetype'),
         url('../fonts/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotomedium';
    src: url('../fonts/roboto-medium-webfont.eot');
    src: url('../fonts/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-medium-webfont.woff2') format('woff2'),
         url('../fonts/roboto-medium-webfont.woff') format('woff'),
         url('../fonts/roboto-medium-webfont.ttf') format('truetype'),
         url('../fonts/roboto-medium-webfont.svg#robotomedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotobold';
    src: url('../fonts/roboto-bold-webfont.eot');
    src: url('../fonts/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('../fonts/roboto-bold-webfont.woff') format('woff'),
         url('../fonts/roboto-bold-webfont.ttf') format('truetype'),
         url('../fonts/roboto-bold-webfont.svg#robotobold') format('svg');
    font-weight: normal;
    font-style: normal;
}
$regular: 'robotoregular';
$regularB: 'bebas_neueregular';
$bold: 'robotobold';
$boldB: 'bebas_neuebold';
$book: 'bebas_neuebook';
$medium: 'robotomedium';
$lightB: 'bebas_neuelight';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
    min-width: 300px;
	position: relative;
    background-color: #0e0e0e;
	flex-direction: column;
	font-family: $regular;
    -webkit-text-size-adjust: 100%;
    overflow-x: hidden;
    color: #c5c5c5;
    padding-top: 160px;
	margin: 0;
    @media screen and (max-width: 1200px) {
        padding-top: 140px;
    }
    @media screen and (max-width: 1024px) {
        padding-top: 90px;
    }
    @media screen and (max-width: 768px) {
        display: block;
        height: auto;
    }
}

.preload {
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    &.load {
        opacity: 0;
        transition: 300ms;
        pointer-events: none;
    }
}

h1 {
	font-family: $boldB;
	font-size: 52px;
	text-transform: uppercase;
	color: #fff;
	line-height: 1;
	margin: 0 0 40px;
    a {
        font-size: 52px;
        color: #fff;
        &:focus {
            color: #fff;
        }
        &:hover {
            color: #e31e24;
        }
    }
	@media screen and (max-width: 1200px) {
		font-size: 32px;
        margin-bottom: 30px;
        a {
            font-size: 32px;
        }
	}
	@media screen and (max-width: 1024px) {
		font-size: 28px;
        margin-bottom: 20px;
        a {
            font-size: 28px
        }
	}
    @media screen and (max-width: 768px) {
        font-size: 26px;
        margin-bottom: 15px;
        a {
            font-size: 24px;
        }
    }
}
h2 {
	font-family: $boldB;
	font-size: 50px;
	color: #fff;
    margin: 0 0 40px;
	a {
        font-size: 50px;
        color: #fff;
        &:focus {
            color: #fff;
        }
        &:hover {
            color: #e31e24;
        }
	}
	@media screen and (max-width: 1200px) {
		font-size: 30px;
        margin-bottom: 30px;
		a {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 1024px) {
		font-size: 24px;
        margin-bottom: 20px;
		a {
			font-size: 24px;
		}
	}
}
h3 {
	font-family: $boldB;
	font-size: 27px;
	color: #fff;
	@media screen and (max-width: 1024px) {
		font-size: 22px;
	}
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}
h4 {
	font-family: $boldB;
	font-size: 22px;
	color: #fff;
    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}
h5 {
	font-family: $boldB;
	font-size: 18px;
	color: #fff;
}
h6 {
	font-family: $boldB;
	font-size: 16px;
	text-transform: uppercase;
	color: #fff;
}
p {
    margin: 0 0 15px;
}
a {
    color: #d1d1d1;
	transition: 300ms;
	text-decoration: none;
	&:focus {
		color: #d1d1d1;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #e31e24;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	font-size: 18px;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
        width: 100%;
        min-height: 45px;
		background-color: #323232;
		font-family: $regular;
		font-size: 16px;
        color: #c6c6c6;
		padding: 12px 20px;
        margin-bottom: 15px;
        box-shadow: none;
        border: none;
        line-height: 1;
        &::placeholder {
            color: #c6c6c6;
        }
	}
    @media screen and (max-width: 1200px) {
        font-size: 16px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 14px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] { 
            min-height: 40px;
            font-size: 14px;
            padding: 10px 15px;
        }
    }
}
.main {
    h1, h2 {
        text-align: center;
    }
	.container {
		padding-top: 50px;
		padding-bottom: 50px;
		@media screen and (max-width: 1024px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}

.container {
    width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
    @media screen and (max-width: 1200px) {
        width: 100%;
    }
}

.close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 8px;
    top: 10px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 25px;
        height: 2px;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}
.btn {
	display: table;
	height: 60px;
	width: auto!important;
	background-color: #ff171e!important;
	font-family: $bold!important;
	font-size: 16px;
	color: #fff!important;
	text-transform: uppercase;
    text-align: center;
	cursor: pointer;
	border-radius: 0px;
	border: 1px solid #ff171e;
	padding: 21px 115px 21px 45px!important;
	position: relative;
    line-height: 1;
	transition: 300ms;
	&:before {
		content: '';
		width: 1px;
		background-color: #fff;
		position: absolute;
		right: 60px;
		top: -1px;
		bottom: -1px;
		transition: 300ms;
	}
	&:after {
		content: '';
		width: 0;
		height: 0;
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
		border-left: 7px solid #fff;
		position: absolute;
		top: 50%;
		right: 27px;
		transform: translateY(-50%);
		transition: 300ms;
	}
	&:focus, &:active {
		background-color: #ff171e!important;
		color: #fff!important;
	}
	&:hover, &.active {
		background-color: #f34e3a!important;
		color: #fff!important;
		border: 1px solid #f34e3a;
		&:before {
			background-color: #fff;
		}
		&:after {
			border-left: 7px solid #fff;
		}
	}	
    &.light {
        background-color: #f34e3a!important;
        border: 1px solid #f34e3a!important;
        &:hover {
            background-color: #ff171e!important;
            border: 1px solid #ff171e!important;
        }
    }
    @media screen and (max-width: 1200px) {
        height: 50px;
        padding: 15px 80px 15px 30px!important;
        &:before {
            right: 50px;
        }
        &:after {
            right: 21px;
        }
    }
    @media screen  and (max-width: 1024px) {
        height: 40px;
        font-size: 14px;
        padding: 11px 60px 10px 20px!important;
        &:before {
            right: 40px;
        }
        &:after {
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid #fff;
            right: 18px;
        }
    }
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		padding-left: 22px;
		margin: 8px 0;
		&:before {
			content: '';
            width: 6px;
            height: 6px;
            background-color: #ff171e;
			position: absolute;
			left: 0;
			top: 10px;
		}
	}
    @media screen and (max-width: 1024px) {
        li {
            &:before {
                top: 7px;
            }
        }
    }
}

.col--location,
.col--phone,
.col--email {
	position: relative;
	padding-left: 25px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #ff171e;
		position: absolute;
		top: 0px;
		left: 0;
	}
}
.col--location:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
}
.col--email:after {
	content: '\f0e0';
	font-size: 16px;
	top: 2px;
}

/* header */
header {
    background-color: #fff;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
    z-index: 9;

    /* header logo */
    .logo {
        display: block;
        width: 200px;
        height: 80px;
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 1;
        opacity: 0;
        pointer-events: none;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
            &.hid {
                display: none;
            }
        }
    }
    /* header logo */

    .close {
        display: none;
    }

    &.header {
        .header__block .down:after {
            opacity: 0;
        }
        .logo {
            opacity: 1;
            pointer-events: auto;
        }
    }
    @media screen and (max-width: 1200px) {
        .logo {
            top: 52px;
        }
    }
	@media screen and (min-width: 1025px) {
        &.show {
            .logo {
                opacity: 1;
                pointer-events: auto;
            }
            .down {
                &:after {
                    opacity: 0!important;
                }
            }
        }
    }
	@media screen and (max-width: 1024px) {
        height: 90px;
        background-color: #000;
        border-bottom: 2px solid rgba(255,23, 30, 1);
        .logo {
            height: 70px;
            opacity: 1;
            pointer-events: auto;
            top: 8px;
            img {
                &.vis {
                    display: none;
                }
                &.hid {
                    display: block;
                }
            }
        }
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 12px;
			top: 30px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #ff171e;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
        .close {
            display: block;
            right: 100%;
            transition: 400ms;
            z-index: 1;
            &:before, &:after {
                background-color: #e31e24;
            }
        }
	}
    &.open {
        .close {
            right: 20px;
        }
    }
    @media screen and (max-width: 480px) {
        .logo {
            width: 175px;
            height: 60px;
            top: 14px;
        }
    }

}
.header__block {
    line-height: 1;
    .up {
        background-color: #000000;
        .container {
            display: flex;          
            height: 60px;
            justify-content: space-between;
            align-items: center;
        }
    }

    .down {
        background: url(../img/pulse.png) repeat-x center center;
        &:after {
            content: '';
            width: 265px;
            height: 62px;
            background: url(../img/pulse.png) repeat-x;
            position: absolute;
            top: 79px;
            left: 0;
            right: 0;
            margin: 0 auto;
            transition: 400ms;
        }
        .container {
            display: flex;
            background-color: #fff;
            justify-content: space-between;
            align-items: center;
            z-index: 1;
        }
    }

    .header--btn {
        display: flex;
        height: 35px;
        min-width: 155px;
        border: 2px solid #e31e24;
        font-family: $bold;
        color: #ffffff;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 3px 20px 5px;
        line-height: 1;
        &:hover {
            background-color: #e31e24;
            color: #fff;
        }
        &.red {
            background-color: #e31e24;
            &:hover {
                background-color: transparent;
            }
        }
        &.violet {
            background-color: #2b2a5c;
            border: 2px solid #2b2a5c;
            &:hover {
                background-color: transparent;
            }
        }
    }
    .header__right {
        display: flex;
        .header--btn {
            margin-left: 20px;
        }
    }
    /* header phones */
    
    /* header phones */

    /* header nav */
    .nav {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin: 25px 0;
        > ul {
            display: flex;
            background-color: #000;
            @include ul-default;
            > li {
                > a {
                    display: flex;
                    font-family: $boldB;
                    font-size: 20px;
                    text-transform: uppercase;
                    color: #ffffff;
                    padding: 15px 10px;
                    &:hover {
                        background-color: #e31e24;
                        color: #fff;
                    }
                }
                &.active > a {
                    background-color: #e31e24;
                    color: #fff;
                }
            }
        }
    }
    /* header nav */

    @media screen and (max-width: 1200px) {
        .up {
            .container {
                height: 50px;
            }
        }
        .header--btn {
            min-width: 130px;
            height: auto;
            font-size: 14px;
            padding: 2px 20px 4px;
        }
        .down {
            &:after {
                width: 220px;
                top: 65px;
            }
        }
        .nav {
            margin: 20px 0;
            > ul > li {
                > a {
                    padding: 15px 20px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        width: calc(100% - 50px);
        height: 100%;
        background-color: #000;
        position: fixed;
        top: 0;
        bottom: 0;
        right: calc(100% + 50px);
        padding: 20px 15px;
        box-shadow: 0px 0px 50px rgba(227, 30, 36, 0.7);
        overflow: auto;
        transition: 400ms;
        z-index: 10;
        &.open {
            right: 50px;
        }
        .container {
            padding: 0;
        }
        .up {
            .container {
                height: auto;
            }
        }
        .down {
            .container {
                background-color: transparent;
            }
            &:after {
                display: none;
            }
        }
        .nav {
            display: block;
            flex-direction: column;
            margin: 10px 0;
            > ul {
                display: block;
                width: 100%;
                > li {
                    display: block;
                    > a {
                        font-size: 18px;
                        padding: 10px 15px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .up .container {
            flex-wrap: wrap;
        }
        .header__right .header--btn {
            margin: 10px 15px 0px 0;
        }
    }
    @media screen and (max-width: 600px) {
        .header--btn {
            width: 100%;
            margin-bottom: 10px!important;
        }
        .header__right {
            width: 100%;
            flex-direction: column;
            .header--btn {
                min-width: 0px;
                margin: 0;
            }
        }
    }
}

.no-scroll {
    overflow: hidden;
    padding-right: 17px;
}

.header__modal {
    display: none;
    background: url(../img/img-1.jpg) no-repeat top center;
    background-size: cover;
    font-size: 18px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    overflow-x: hidden;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 99;
    > img {
        display: none;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .container {
        padding: 45px 15px;
    }
    .header--price__block {
        width: 800px;
        margin: 0 auto;
        .header__title {
            display: block;
            font-family: $boldB;
            font-size: 40px;
            text-transform: uppercase;
            text-align: center;
            color: #fff;
            margin: 20px 0 25px;
            &:first-of-type {
                margin-top: 0;
            }
        }
        table {
            width: 100%;
            font-size: 20px;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            border: 3px solid #e1e1e1;
            border-collapse: collapse;
            tr {
                th {
                    width: 50%;
                    font-size: 30px;
                    color: #fff;
                    padding: 15px 10px;
                    border-bottom: 3px solid #e1e1e1;
                    &:first-of-type {
                        border-right: 3px solid #e1e1e1;
                    }
                }
                td {
                    padding: 15px 10px;
                    border-right: 3px solid #e1e1e1;
                    border-bottom: 1px solid #e1e1e1;
                }
                &:last-of-type {
                    td {
                        border-bottom: none;
                    }
                }
            }
        }
        ul {
            list-style-type: decimal;
            padding-left: 20px;
        }
        .btn {
            margin: 15px auto 0;
        }
    }
    .close {
        position: fixed;
        right: 30px;
        top: 30px;
        &:after, &:before {
            background-color: #f34e3a;
        }
    }
    &#header--modal2 {
        .header--price__block table {
            font-size: 18px;
            tr td {
                padding: 10px 10px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .container {
            padding: 30px 50px;
        }
        .header--price__block {
            .header__title {
                font-size: 40px;
                margin: 35px 0 20px;
            }
            table {
                font-size: 24px;
                tr {
                    th {
                        font-size: 30px;
                    }
                }
            }
            .btn {
                margin-top: 30px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        font-size: 16px;
        bottom: 1px;
        .container {
            padding: 30px 50px;
        }
        .close {
            right: 15px;
            top: 15px;
        }
        .header--price__block {
            width: 100%;
            .header__title {
                font-size: 30px;
            }
            table {
                font-size: 20px;
                tr {
                    th {
                        font-size: 24px;
                        padding: 15px 10px;
                    }
                    td {
                        padding: 10px 10px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        font-size: 14px;
        .header--price__block {
            .header__title {
                font-size: 24px;
                margin: 20px 0 10px;
            }
            table {
                font-size: 16px;
                tr {
                    th {
                        font-size: 20px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .container {
            padding: 20px 40px;
        }
        .close {
            top: 10px;
            right: 10px;
        }
    }
}

/* header */