/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* custom cursor */
.cursor {
    width: 25px;
    height: 25px;
    position: absolute;
    pointer-events: none;
    transition-duration: 80ms;
    z-index: 999;
    &:after {
        content: '';
        background-color: rgba(255,255,255,0.7);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 100%;
        animation: cursorAnim1 .3s forwards;
    }
    &.expand:after {
        animation: cursorAnim2 .3s forwards;
    }
    @media screen and (max-width: 1024px) {
        opacity: 0;
        visibility: hidden;
    }
}
.cursor--dots {
    position: absolute;
    background-color: #000;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    pointer-events: none;
    z-index: 1000;
    @media screen and (max-width: 1024px) {
        opacity: 0;
        visibility: hidden;
    }
}
@keyframes cursorAnim1 {
    0% {
        transform: scale(0.7);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes cursorAnim2 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.7);
    }
}
/* custom cursor */

/* main slider */
.slider--block {
    position: relative;
    .bg {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        pointer-events: none;
        img {
            display: block;
            width: 100%;
        }
    }
    .slider__text {
        width: 700px;
        position: absolute;
        top: 35%;
        left: 0;
        right: 0;
        text-align: center;
        transform: translateY(-50%);
        z-index: 1;
        margin: 0 auto;
        .slider__img {
            display: block;
            margin: 0 auto 40px;
            img {
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }
            &.show {
                opacity: 0;
            }
        }
        .slider__title {
            font-family: $boldB;
            font-size: 72px;
            text-transform: uppercase;
            color: #ffffff;
        }
    }
    @media screen and (max-width: 1440px) {
        .slider__text {
            .slider__img {
                width: 550px;
            }
            .slider__title {
                font-size: 60px;
            }
        }
    }
    @media screen and (max-width: 1366px) {
        .slider__text {
            .slider__img {
                width: 500px;
            }
            .slider__title {
                font-size: 50px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .slider__text {
            width: auto;
            .slider__img {
                width: 400px;
                margin-bottom: 20px;
            }
            .slider__title {
                font-size: 40px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .slider__text {
            .slider__img {
                width: 300px;
            }
            .slider__title {
                font-size: 30px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .slider__text {
            top: 30%;
            .slider__img {
                width: 200px;
                margin-bottom: 10px;
            }
            .slider__title {
                font-size: 22px;
            }
        }
    }
}
.slider {
	margin-bottom: 0px!important;
	.slick-slide {
        height: calc(100vh);
		overflow: hidden;
		position: relative;
		outline: none;
		> img {
			display: block;
			width: 100%;
			height: 100%;
            object-fit: cover;
            object-position: center center;
            filter: grayscale(100%);
		}
	}
    &.dots {
        .slick-dots {
            width: auto;
            right: auto;
            left: 50%;
            bottom: 25%;
            margin-left: -625px;
            li {
                display: block;
                margin: 13px 0;
                a {
                    padding: 5px 20px 5px 0;
                    &:after {
                        content: '';
                        width: 3px;
                        height: auto;
                        top: 0;
                        bottom: 100%;
                        left: auto;
                        right: 0;
                    }
                    &:hover {
                        color: #ff1118;
                    }
                }
                &.slick-active {
                    a {
                        color: #ff1118;
                        &:after {
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .slick-slide {
            height: calc(100vh - 50px);
            img {
                object-position: center left;
            }
        }
    }
    @media screen and (max-width: 1280px) {
        &.dots {
            .slick-dots {
                left: 15px;
                margin: 0;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .slick-slide {
            height: 500px;
        }
        &.dots {
            .slick-dots {
                li a {
                    padding: 3px 15px 3px 0;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            height: 400px;
        }
        &.dots {
            .slick-dots {
                display: flex;
                left: 0;
                right: 0;
                justify-content: center;
                bottom: 20%;
                li {
                    display: table;
                    margin: 0 10px;
                    a {
                        padding: 0px 3px 10px 3px;
                        &:after {
                            height: 3px;
                            width: 0;
                            top: auto;
                            bottom: 0;
                            right: auto;
                            left: 0;
                        }
                    }
                    &.slick-active {
                        a {
                            &:after {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .slick-slide {
            height: 250px;
        }
        &.dots {
            .slick-dots {
                bottom: 60px;
                li {
                    margin: 0 5px;
                }
            }
        }
    }
    @media screen and (max-width: 360px) {
        &.dots {
            .slick-dots {
                bottom: 50px;
            }
        }
    }
}
.arrow {
    .slick-arrow {
        display: flex;
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: rgba(0,0,0,0.5);
        font-family: 'fontAwesome';
        font-size: 22px;
        z-index: 1;
        &:before {
            display: none;
        }
        &:after {
            content: '\f054';
            color: #fff;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin: 0 auto;
            transition: 300ms;
        }
        &.slick-prev {
            left: 25px;
            &:after {
                content: '\f053';
            }
        }
        &.slick-next {
            right: 25px;
        }
        &:hover {
            background-color: rgba(0,0,0,0.9);
            &:after {
                color: #e31e24;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .slick-arrow {
            width: 40px;
            height: 40px;
            &:after {
                font-size: 18px;
            }
            &.slick-prev {
                left: 15px;
            }
            &.slick-next {
                right: 15px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .slick-arrow {
            width: 30px;
            height: 30px;
            &:after {
                font-size: 14px;
            }
            &.slick-prev {
                left: 15px;
            }
            &.slick-next {
                right: 15px;
            }
        }
    }
}
.dots {
    .slick-dots {
        bottom: 30px;
        line-height: 1;
        li {
            width: auto;
            height: auto;
            margin: 0px 11px;
            a {
                display: block;
                width: auto;
                height: auto;
                background-color: transparent;
                font-family: $boldB;
                font-size: 24px;
                color: #fff;
                padding: 0 0 18px 0;
                position: relative;
                opacity: 0.5;
                overflow: hidden;
                transition: 300ms;
                &:after {
                    content: '';
                    height: 3px; 
                    background-color: #ff1118;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 101%;
                    transition: 300ms;
                }
                &:before {
                    display: none;
                }
                &:hover {
                    opacity: 1;
                    &:after {
                        right: 0;
                    }
                }
            }
            &.slick-active {
                a {
                    opacity: 1;
                    &:after {
                        right: 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .slick-dots {
            li a {
                font-size: 20px;
                padding: 0 0 15px 0;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .slick-dots {
            li {
                margin: 0 6px;
                a {
                    padding-bottom: 10px;
                }
            }
        }
    }
    
}
/* main slider */

/* main advantages */
.advantages {
    position: relative;
    margin-top: -15vh;
    text-align: center;
    .container {
        width: 970px;
    }
    .advantages__text {
        padding: 0 75px;
        h2 {
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 1680px) {
        .advantages__text {
            padding: 0 100px;
            h2 {
                font-size: 46px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .container{
            width: 800px;
        }
        .advantages__text {
            h2 {
                font-size: 40px;
            }
        }
    }
    @media screen and (max-width: 1280px) {
        .container {
            width: 700px;
        }
        .advantages__text {
            h2 {
                font-size: 32px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 0;
        .container {
            width: 100%;
        }
        .advantages__text {
            padding: 0;
            h2 {
                font-size: 24px;
            }
        }
    }
}
.advantages__block {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -90px;
    .advantages__item {
        width: calc(33.33% - 180px);
        margin: 40px 90px;
        .advantages__img {
            height: 65px;
            width: 85px;
            margin: 0 auto 25px;
            img {
                height: 100%;
                max-width: 100%;
                object-fit: contain;
                object-position: center center;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 0;
        .advantages__item {
            width: calc(33.33% - 100px);
            margin: 15px 25px;
            .advantages__img {
                margin: 0 auto 15px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .advantages__item {
            width: calc(33.33% - 50px);
        }
    }
    @media screen and (max-width: 500px) {
        .advantages__item {
            width: calc(50% - 20px);
            margin: 15px 10px;
        }
    }
    @media screen and (max-width: 400px) {
        justify-content: space-between;
        .advantages__item {
            width: calc(50% - 5px);
            margin: 15px 0;
            .advantages__img {
                width: 60px;
                height: 50px;
            }
        }
    }
}
/* main advantages */

/* main about */
.about {
    display: flex;
    background-color: #1b1b1b;
    align-items: center;
    .left {
        display: flex;
        width: 50%;
        justify-content: flex-end;
    }
    .right {
        width: 50%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
    .about__block {
        width: 570px;
        padding: 25px 80px 25px 0;
        .btn {
            margin-top: 45px;
        }
    }
    h1 {
        text-align: left;
    }
    @media screen and (max-width: 1440px) {
        align-items: stretch;
    }
    @media screen and (max-width: 1200px) {
        .about__block {
            width: 100%;
            padding: 20px 15px;
        }
    }
    @media screen and (max-width: 1024px) {
        .about__block {
            .btn {
                margin-top: 25px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        .left, .right {
            width: 100%;
            padding: 0;
        }
        .left {
            order: 2;
        }
        .right {
            order: 1;
        }
    }
}
/* main about */

/* main catalog */
.cat {
    padding: 95px 0;
    @media screen and (max-width: 1200px) {
        padding: 50px 0;
    }
    @media screen and (max-width: 1024px) {
        padding: 35px 0;
    }
    @media screen and (max-width: 768px) {
        padding: 25px 0;
    }
}
.cat__block {
    display: flex;
    flex-wrap: wrap;
    .cat__item {
        display: flex;
        height: 400px;
        width: calc(50% - 10px);
        background-color: #ffffff;
        position: relative;
        transition: 300ms;
        margin: 10px;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(255,255,255, 0.7);
            z-index: 1;
        }
        &:nth-of-type(odd) {
            margin-left: 0;
        }
        &:nth-of-type(even) {
            margin-right: 0;
        }
        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            position: absolute;
            top: 0;
            left: 0;
        }
        .cat__img {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;
            img {
                width: 520px;
                height: 160px;
                object-fit: contain;
                object-position: center center;
            }
        }
        .cat__title {
            display: block;
            font-family: $boldB;
            font-size: 40px;
            text-transform: uppercase;
            color: #0e0e0e;
            margin-bottom: 30px;
        }
        .cat__icon {
            display: flex;
            position: absolute;
            top: 25px;
            right: 30px;
            z-index: 1;
            .cat__btn {
                position: relative;
                margin-left: 30px;
                img {
                    display: block;
                    width: auto;
                    height: 42px;
                    object-fit: contain;
                    object-position: center center;
                }
            }
        }
        .cat__modal {
            display: none;
            width: 320px;
            font-size: 16px;
            text-align: center;
            background-color: #1b1b1b;
            position: absolute;
            top: 101%;
            right: 100%;
            padding: 30px 20px;
            box-shadow: 0px 0px 20px rgba(27, 27, 27, 0.5);
            z-index: 1;
            h3 {
                margin: 0 0 12px;
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                @include ul-default;
            }
        }
        &:hover {
            transform: scale(1.1);
            box-shadow: 0px 0px 25px rgba(0,0,0,0.9);
            z-index: 2;
            .cat__title {
                color: #2b2a5c;
            }
        }
        &:nth-of-type(odd):hover {
            transform-origin: 0 50%;
        }
        &:nth-of-type(even):hover {
            transform-origin: 100% 50%;
        }
    }
    @media screen and (max-width: 1440px) {
        .cat__item {
            height: 350px;
            .cat__img {
                img {
                    width: 425px;
                    height: 135px;
                }
            }
            .cat__title {
                margin: 0 0 15px;
            }
            &:hover {
                transform: scale(1);
            }
        }
    }
    @media screen and (min-width: 1200px) {
        width: 1200px;
        margin: 0 auto;
        .cat__item {
            height: 350px;
            .cat__img {
                img {
                    height: 120px;
                }
            }
        }
    }
    @media screen and (max-width: 1280px) {
        .cat__item {
            height: 300px;
            .cat__img {
                img {
                    width: 350px;
                    height: 110px;
                }
            }
            .cat__title {
                font-size: 35px;
            }
            .cat__icon {
                top: 15px;
                right: 15px;
                .cat__btn {
                    margin-left: 20px;
                    img {
                        height: 35px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 0 5px;
        margin: 0;
        .cat__item {
            width: calc(50% - 20px);
            padding: 15px;
            margin: 10px!important;
            &:nth-of-type(odd) {
                .cat__modal {
                    left: 100%;
                    right: auto;
                }
            }
            .cat__img {
                img {
                    width: auto;
                    max-width: 100%;
                    height: auto;
                }
            }
            .cat__title {
                font-size: 30px;
                margin-bottom: 10px;
            }
            .cat__icon .cat__btn {
                margin-left: 10px;
                img {
                    height: 30px;
                }
            }
        }
        .cat__item .cat__modal {
            width: 300px;
            padding: 20px 15px;
        }
    }
    @media screen and (max-width: 768px) {
        .cat__item .cat__modal {
            font-size: 14px;
        }
    }
    @media screen and (max-width: 600px) {
        .cat__item {
            height: 250px;
            .cat__title {
                font-size: 25px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        padding: 0 15px;
        .cat__item {
            width: 100%;
            margin: 10px 0!important;
            &:nth-of-type(odd) {
                .cat__modal {
                    right: 0;
                    left: auto;
                }
            }
            .cat__modal {
                right: 0;
            }
            .cat__icon {
                top: 10px;
                right: 10px;
                .cat__btn {
                    margin-left: 10px;
                    img {
                        height: 30px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 360px) {
        .cat__item {
            .cat__modal {
                width: 260px;
                .cat__phone {
                    flex-direction: column;
                    li {
                        &:nth-of-type(even) {
                            margin-left: 0;
                        }
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.cat__phone {
    display: flex;
    max-width: 300px;
    font-size: 16px;
    flex-wrap: wrap;
    font-family: $medium;
    li {
        position: relative;
        &:nth-of-type(even) {
            margin-left: 15px;
            &:before {
                content: '/';
                position: absolute;
                left: -10px;
                top: 3px;
            }
        }
        a {
            pointer-events: none;
        }
    }
    @media screen and (max-width: 768px) {
        font-size: 14px;
        li {
            a {
                pointer-events: auto;
            }
        }
    }
}

.cat--table {
    width: 100%;
    max-width: 265px;
    font-size: 16px;
    tr {
        td {
            padding: 3px 5px;
            &:first-of-type {
                text-align: left;
                padding-left: 0;
            }
            &:last-of-type {
                text-align: right;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        font-size: 14px;
    }
}

.cat--inside {
    margin-bottom: 35px;
    .container {
        padding-top: 0;
        padding-bottom: 0;
    }
    h2 {
        text-align: center;
    }
    .btn {
        margin: 25px auto 0;
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 25px;
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 15px;
    }
}
.cat--inside__list {
    @include ul-default;
    display: flex;
    justify-content: center;
    line-height: 1;
    margin-bottom: 30px;
    li {
        margin: 0 15px;
        a {
            display: block;
            font-family: $boldB;
            font-size: 26px;
            color: #fff;
            text-align: center;
            padding-bottom: 20px;
            position: relative;
            &:after {
                content: '';
                width: 0;
                height: 3px;
                background-color: #ff1118;
                position: absolute;
                bottom: 0;
                left: 0;
                transition: 300ms;
            }
            &:hover {
                &:after {
                    width: 100%;
                }
            }
        }
        &.active a {
            &:after {
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 15px 0;
        li {
            margin: 0 15px;
            a {
                font-size: 22px;
                padding-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        li {
            a {
                font-size: 20px;
                padding-bottom: 10px;
            }
        }
    }
    @media screen and (max-width: 680px) {
        flex-wrap: wrap;
        li {
            margin: 5px 10px;
        }
    }
    @media screen and (max-width: 480px) {
        li {
            a {
                font-size: 18px;
            }
        }
    }
}
.cat--inside__block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    @media screen and (max-width: 1024px) {
        margin: 0 -7.5px;
    }
}
.cat--inside__item {
    min-height: 550px;
    width: calc(25% - 30px);
    text-align: center;
    transition: 0ms;
    padding-bottom: 20px;
    position: relative;
    margin: 0px 15px 15px;
    &:before {
        content: '';
        background-color: #1b1b1b;
        position: absolute;
        top: -60px;
        left: -60px;
        right: -60px;
        bottom: 0;
        box-shadow: 0px 0px 25px rgba(0,0,0,0.9);
        transition: 300ms;
        opacity: 0;
        z-index: -1;
    }
    .cat--inside__img {
        height: 350px;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
    h3 {
        transition: 300ms;
        margin: 20px 0 17px;
    }
    ul {
        font-family: $medium;
        @include ul-default;
        li {
            margin: 3px 0;
        }
    }
    &:hover {
        color: #d1d1d1;
        z-index: 1;
        &:before {
            opacity: 1;
        }
        h3 {
            color: #ff171e;
        }
    }
    @media screen and (max-width: 1366px) {
        &:before {
            top: -45px;
            right: -45px;
            left: -45px;
        }
    }
    @media screen and (max-width: 1280px) {
        &:before {
            top: -15px;
            right: -15px;
            left: -15px;
        }
    }
    @media screen and (max-width: 1200px) {
        .cat--inside__img {
            height: 300px;
            img {
                object-position: top center;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        width: calc(25% - 15px);
        min-height: 0px;
        margin: 0 7.5px 15px;
    }
    @media screen and (max-width: 768px) {
        width: calc(33.33% - 15px);
        padding-bottom: 10px;
        .cat--inside__img {
            height: 275px;
        }
    }
    @media screen and (max-width: 600px) {
        width: calc(50% - 15px);
        .cat--inside__img {
            height: 325px;
        }
    }
    @media screen and (max-width: 480px) {
        width: calc(50% - 15px);
        .cat--inside__img {
            height: 275px;
        }
    }
    @media screen and (max-width: 380px) {
        width: 100%;
        .cat--inside__img {
            height: auto;
            img {
                height: auto;
            }
        }
    }
}

.cat--group__block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    .cat--group__item {
        width: calc(33.33% - 30px);
        margin: 15px;
        .cat--group__img {
            height: 250px;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        h3 {
            text-align: center;
            transition: 300ms;
        }
        &:hover {
            h3 {
                color: #ff1118;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 0 -10px;
        .cat--group__item {
            width: calc(33.33% - 20px);
            margin: 10px;
            .cat--group__img {
                height: 200px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .cat--group__item {
            width: calc(33.33% - 20px);
            .cat--group__img {
                height: 150px;
            }
            h3 {
                margin: 15px 0;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .cat--group__item {
            width: calc(50% - 20px);
            .cat--group__img {
                height: 175px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .cat--group__item {
            .cat--group__img {
                height: 150px;
            }
        }
    }
    @media screen and (max-width: 380px) {
        margin: 0;
        .cat--group__item {
            width: 100%;
            margin: 10px 0;
            .cat--group__img {
                height: auto;
                img {
                    height: auto;
                }
            }
        }
    }

}
/* main catalog */

/* pagination */
.pagination {
    width: 100%;
    line-height: 1;
    ul {
        @include ul-default;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        li {
            margin: 0 10px 10px;
            a {
                display: block;
                position: relative;
                font-family: $boldB;
                font-size: 24px;
                color: #fff;
                opacity: 0.7;
                padding: 0 2px 18px;
                &:after {
                    content: '';
                    width: 0;
                    height: 3px;
                    background-color: #ff1118;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transition: 300ms;
                }
                &:hover {
                    opacity: 1;
                    &:after {
                        width: 100%;
                    }
                }
            }
            &.active  {
                a {
                    opacity: 1;
                    &:after {
                        width: 100%;
                    }
                }
            }
            &.prev,
            &.next {
                a {
                    width: 42px;
                    height: 42px;
                    font-size: 20px;
                    opacity: 1;
                    &:before {
                        content: '\f054';
                        font-family: 'fontAwesome';
                        color: #ff1118;
                        text-align: center;
                        position: absolute;
                        top: 2px;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                    }
                    &:hover {
                        opacity: 0.7;
                        &:after {
                            display: none;
                        }
                    }
                }
            }
            &.prev {
                a {
                    &:before {
                        content: '\f053';
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 15px 0;
        ul {
            li {
                a {
                    font-size: 20px;
                    padding-bottom: 15px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        ul {
            li {
                margin: 0 5px 10px;
                a {
                    padding-bottom: 10px;
                }
            }
        }
    }
}
/* pagination */

/* main img slider */
.img--slider {
    overflow: hidden;
    .slick-slide {
        height: 600px;
        outline: none;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.4);
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
        .img--slider__block {
            text-align: center;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            padding: 0 25px;
            margin: 0 auto;
            transform: translateY(-50%);
            z-index: 1;
            line-height: 1;
            h2 {
                min-height: 100px;
                margin: 15px 0px;
            }
            .btn {
                margin: 0 auto;
            }
        }
    }
    .slick-dots {
        width: auto;
        left: 15px;
        right: 15px;
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 20px!important;
        .slick-slide {
            height: auto;
            img {
                height: auto;
            }
            .img--slider__block {
                h2 {
                    min-height: 0px;
                }
            }
        }
        &.dots {
            .slick-dots {
                bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            height: 350px;
            img {
                height: 100%;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .slick-slide {
            height: 300px;
        }
    }
    @media screen and (max-width: 480px) {
        .slick-slide {
            height: 250px;
            .img--slider__block {
                h2 {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
/* main img slider */

/* main news */
.news__block {
    display: flex;
    justify-content: space-between;
    .news__item {
        display: flex;
        width: calc(50% - 30px);
        flex-direction: column;
        font-family: $medium;
        .news__img {
            height: 300px;
            margin-bottom: 20px;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        .news__info {
            display: flex;
            align-items: flex-start;
            .date {
                width: 130px;
                background-color: #f34e3a;
                font-family: $boldB;
                font-size: 27px;
                color: #fff;
                text-align: center;
                padding: 10px;
                line-height: 1;
                margin: 0 15px 0 0;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .news__item {
            width: calc(50% - 7.5px);
            .news__img {
                height: 250px;
            }
            .news__info {
                .date {
                    font-size: 22px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .news__item {
            .news__img {
                height: 200px;
            }
            .news__info {
                .date {
                    font-size: 20px;
                    padding: 5px;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        .news__item {
            .news__img {
                height: 175px;
            }
            .news__info {
                flex-wrap: wrap;
                .date {
                    width: auto;
                    font-size: 18px;
                    margin: 0 0 10px;
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        flex-wrap: wrap;
        .news__item {
            width: 100%;
            margin: 0 0 15px;
            .news__img {
                height: auto;
                img {
                    height: auto;
                }
            }
            .news__info {
                .date {
                    font-size: 16px;
                }
            }
        }
    }
}
/* main news */

/* main map */
.map {
    height: 500px;
    > ymaps, .ymaps-2-1-73-map {
        height: 100%!important;
    } 
    .map__list {
        display: none;
        position: absolute;
    }
    .ymaps-2-1-73-balloon {
        background-color: #1b1b1b;
        font-size: 16px;
        color: #d1d1d1;
        text-align: center;
        .ymaps-2-1-73-balloon__content {
            width: 340px!important;
            background-color: #1b1b1b;
            padding: 30px 20px 20px!important;
            margin-right: 0!important;
            > ymaps {
                width: 100%!important;
                height: auto!important;
            }
        }
        .ymaps-2-1-73-balloon__tail {
            display: none;
        }
        .ymaps-2-1-73-balloon__close-button {
            @extend .close;
        }
        h3 {
            text-align: center;
            margin: 0 0 15px ;
        }
        ul {
            @include ul-default;
            li {
                text-align: center;
                margin: 8px 8px;
            }
        }
        .cat__phone {
            justify-content: center;
        }
        .map__block {
            font-family: $regular;
            font-size: 16px;
        }
    }
    [class*="ymaps-2"][class*="-ground-pane"] {
        filter: grayscale(100%);
    }
    @media screen and (max-width: 1024px) {
        height: 400px;
    }
    @media screen and (max-width: 768px) {
        height: 300px;
    }
    @media screen and (max-width: 533px) {
        .ymaps-2-1-73-balloon .ymaps-2-1-73-balloon__content {
            width: auto!important;
            max-width: 340px!important;
            margin: 0 auto!important;
            .cat__phone {
                margin: 0 auto;
            }
        }
    }
}
/* main map */

/* main content */